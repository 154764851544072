import i18n from 'config/i18n';
import {
  UpdateCheckInModalForceProps,
  UpdateCheckInFormBoxProps,
  UpdateCheckInFormTextInputDateProps,
  UpdateCheckInFormTextInputTimeProps,
  UpdateCheckInFormEmailInputValueProps,
  UpdateCheckInInputFormKey,
  UpdateCheckInFormTextInputCardsProps,
  UpdateCheckInQrModalProps,
  UpdateCardKey
} from './models';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/date';
import { disabledDate, disabledDateTime } from 'utils/date';
import moment from 'moment';
import { UpdateCheckInRoomModel } from 'core/domain/hotels/models';

const getInitialDigitalBox = (): UpdateCheckInFormBoxProps => ({
  title: i18n.t('_ACCESS_HOTEL_DRAWER_DIGITAL_BOX_TITLE'),
  onVisibleContent: () => null,
  isContentVisible: false,
  isError: false,
});

const getInitialEmails = (): UpdateCheckInFormEmailInputValueProps => ({
  errorMessage: '',
  label: i18n.t('_ACCESS_HOTEL_DRAWER_EMAIL_LABEL'),
  placeholder: i18n.t('_ACCESS_HOTEL_DRAWER_EMAIL_PLACEHOLDER'),
  type: 'email',
  value: '',
  valueList: [],
  onChangeEmail: () => null,
  onRemoveEmailItemList: () => null,
  onEmailSelected: () => null,
  isValid: false,
  isDisabled: false,
  isError: false,
  isRequired: false,
});

const getInitialCardListBox = (): UpdateCheckInFormBoxProps => ({
  title: i18n.t('_ACCESS_HOTEL_DRAWER_CARD_LIST_BOX_TITLE'),
  onVisibleContent: () => null,
  isContentVisible: false,
  isError: false,
});

export const cardKeysGroupUpdate: UpdateCardKey[] = [
  UpdateCheckInInputFormKey.CARD_1,
  UpdateCheckInInputFormKey.CARD_2,
  UpdateCheckInInputFormKey.CARD_3,
  UpdateCheckInInputFormKey.CARD_4,
  UpdateCheckInInputFormKey.CARD_5,
  UpdateCheckInInputFormKey.CARD_6,
  UpdateCheckInInputFormKey.CARD_7,
  UpdateCheckInInputFormKey.CARD_8,
  UpdateCheckInInputFormKey.CARD_9,
];

export const getFollowingKeyUpdate = (key: UpdateCardKey, hasExtraCards: boolean): UpdateCardKey | null => {
  const index = cardKeysGroupUpdate.indexOf(key);
  const length = hasExtraCards ? cardKeysGroupUpdate.length - 1 : 2;
  return index === length ? null : cardKeysGroupUpdate[index + 1];
};


const getInitialUpdateCard = ({
  label,
  key,
  isRequired = false,
  isVisible = true
}: {
  label: string,
  key: UpdateCardKey,
  isRequired?: boolean,
  isVisible?: boolean
}): UpdateCheckInFormTextInputCardsProps => ({
  key,
  followingKey: null,
  errorMessage: '',
  label,
  placeholder: i18n.t('_ACCESS_HOTEL_DRAWER_CARD_PLACEHOLDER', { label }),
  value: '',
  ref: null,
  onChangeValue: () => null,
  isDisabled: false,
  isError: false,
  isRequired,
  isVisible,
});

const getInitialModalForce = (): UpdateCheckInModalForceProps => ({
  acceptButtonText: i18n.t('_ACCESSES_HOTEL_CHECK_IN_FORCE_SAVE_MODAL_ACCEPT'),
  cancelButtonText: i18n.t('_ACCESSES_HOTEL_CHECK_IN_FORCE_SAVE_MODAL_CANCEL'),
  checkInData: {} as UpdateCheckInRoomModel,
  description: '',
  title: '',
  onAccept: () => null,
  onCancel: () => null,
  isLoading: false,
  isVisible: false,
});

const getInitialUpdateQrModal = (): UpdateCheckInQrModalProps => ({
  title: i18n.t('_ACCESS_HOTEL_MODAL_TITLE_QR'),
  cancelText: i18n.t('_ACCESS_HOTEL_MODAL_CANCEL_QR'),
  checkText: i18n.t('_ACCESS_HOTEL_MODAL_CHECK_BOX_QR'),
  refreshText: i18n.t('_ACCESS_HOTEL_MODAL_REFRESH'),
  selectedCard: UpdateCheckInInputFormKey.CARD_1,
  onScanQr: () => null,
  onCancel: () => null,
  onRefresh: () => null,
  onFollowingScan: () => null,
  isVisible: false,
  isLoading: false,
  isFollowingScan: true,
});

const getInitialDate = (): UpdateCheckInFormTextInputDateProps => ({
  errorMessage: '',
  formatDate: DATE_FORMAT,
  label: i18n.t('_ACCESS_HOTEL_DRAWER_RANGE'),
  placeholder: '',
  value: null,
  onDisabledDate: disabledDate,
  onChangeRange: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
});

const getInitialStartTime = (): UpdateCheckInFormTextInputTimeProps => ({
  errorMessage: '',
  formatTime: TIME_FORMAT,
  label: i18n.t('_ACCESS_HOTEL_DRAWER_START_TIME'),
  placeholder: '',
  value: null,
  onDisabledTime: disabledDateTime,
  onChangeTime: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
});

const getInitialEndTime = (): UpdateCheckInFormTextInputTimeProps => ({
  errorMessage: '',
  formatTime: TIME_FORMAT,
  label: i18n.t('_ACCESS_HOTEL_DRAWER_END_TIME'),
  placeholder: '',
  value: moment('12:00', TIME_FORMAT),
  onDisabledTime: disabledDateTime,
  onChangeTime: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
});

export const INITIAL_CARD_LIST_BOX = getInitialCardListBox();
export const INITIAL_DIGITAL_BOX = getInitialDigitalBox();
export const INITIAL_EMAILS = getInitialEmails();
export const INITIAL_END_DATE = getInitialDate();
export const INITIAL_END_TIME = getInitialEndTime()
export const INITIAL_MODAL_FORCE = getInitialModalForce();
export const INITIAL_QR_MODAL = getInitialUpdateQrModal();
export const INITIAL_START_DATE = getInitialDate();
export const INITIAL_START_TIME = getInitialStartTime();

export const labelCard1 = i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_LABEL', { cardNumber: '1' });
export const labelCard2 = i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_LABEL', { cardNumber: '2' });
export const labelCard3 = i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_LABEL', { cardNumber: '3' });
export const labelCard4 = i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_LABEL', { cardNumber: '4' });
export const labelCard5 = i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_LABEL', { cardNumber: '5' });
export const labelCard6 = i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_LABEL', { cardNumber: '6' });
export const labelCard7 = i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_LABEL', { cardNumber: '7' });
export const labelCard8 = i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_LABEL', { cardNumber: '8' });
export const labelCard9 = i18n.t('_ACCESSES_HOTEL_CHECK_IN_CARD_LABEL', { cardNumber: '9' });

export const INITIAL_CARD1 = getInitialUpdateCard({
  label: labelCard1,
  key: UpdateCheckInInputFormKey.CARD_1,
  isRequired: true,
});

export const INITIAL_CARD2 = getInitialUpdateCard({
  label: labelCard2,
  key: UpdateCheckInInputFormKey.CARD_2
});

export const INITIAL_CARD3 = getInitialUpdateCard({
  label: labelCard3,
  key: UpdateCheckInInputFormKey.CARD_3
});

export const INITIAL_CARD4 = getInitialUpdateCard({
  label: labelCard4,
  key: UpdateCheckInInputFormKey.CARD_4,
  isVisible: false,
});

export const INITIAL_CARD5 = getInitialUpdateCard({
  label: labelCard5,
  key: UpdateCheckInInputFormKey.CARD_5,
  isVisible: false,
});

export const INITIAL_CARD6 = getInitialUpdateCard({
  label: labelCard6,
  key: UpdateCheckInInputFormKey.CARD_6,
  isVisible: false,
});

export const INITIAL_CARD7 = getInitialUpdateCard({
  label: labelCard7,
  key: UpdateCheckInInputFormKey.CARD_7,
  isVisible: false,
});

export const INITIAL_CARD8 = getInitialUpdateCard({
  label: labelCard8,
  key: UpdateCheckInInputFormKey.CARD_8,
  isVisible: false,
});

export const INITIAL_CARD9 = getInitialUpdateCard({
  label: labelCard9,
  key: UpdateCheckInInputFormKey.CARD_9,
  isVisible: false,
});
