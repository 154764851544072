import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import PeripheralDevices from '../../../../organisms/PeripheralDevices';
import { useTabDevices } from './resources/useTabDevices';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { Icon } from 'components/atoms/Icons';
import CreateDeviceWithoutGatewayDrawer from 'components/organisms/CreateDeviceWithoutGatewayDrawer';
import UpdateDeviceWithoutGatewayDrawer from 'components/organisms/UpdateDeviceWithoutGatewayDrawer';
import DeleteDeviceWithoutGatewayModal from 'components/molecules/DeleteDeviceWithoutGatewayModal';
import AddMaintenanceActionDrawer from 'components/organisms/AddMaintenanceActionDrawer';
import './styles.scss';

const TabDevices: FC = () => {
  const {
    devices,
    columns,
    expandedColumns,
    gatewayId,
    createDeviceOptions,
    updateDeviceOptions,
    deleteDeviceOptions,
    maintenanceActionsDrawerOptions,
    loading,
  } = useTabDevices();

  const { createDeviceLiterals, onOpenCreateDeviceDrawer, onCloseCreateDeviceDrawer, createDeviceDrawerVisible } = createDeviceOptions;
  const { updateDeviceLiterals, onCloseUpdateDeviceDrawer, updateDeviceDrawerVisible } = updateDeviceOptions;
  const { maintenanceDevice, isMaintenanceActionsDrawerVisible, onCloseMaintenanceActionsDrawer } = maintenanceActionsDrawerOptions;
  const { deleteDeviceModalTranslations, deleteDeviceModalVisible, onCloseDeleteDeviceModal, onDeleteDevice, deleting } = deleteDeviceOptions;
  const { title, text, mainButtonText, secondaryButtonText } = deleteDeviceModalTranslations;

  return (
    <>
      <Paper>
        <div className={'TabDevices__button'}>
          <DefaultButton
            onClick={onOpenCreateDeviceDrawer}
            icon={<Icon.Plus />}
            title={createDeviceLiterals.createDeviceButtonText}
          />
        </div>
        <PeripheralDevices
          loading={loading}
          devices={devices}
          columns={columns}
          expandedColumns={expandedColumns}
        />
      </Paper>
      <CreateDeviceWithoutGatewayDrawer
        title={createDeviceLiterals.title}
        options={createDeviceOptions}
        onClose={onCloseCreateDeviceDrawer}
        open={createDeviceDrawerVisible}
      />
      <UpdateDeviceWithoutGatewayDrawer
        options={updateDeviceOptions}
        title={updateDeviceLiterals.title}
        open={updateDeviceDrawerVisible}
        onClose={onCloseUpdateDeviceDrawer}
      />
      <DeleteDeviceWithoutGatewayModal
        deleting={deleting}
        mainButtonText={mainButtonText}
        secondaryButtonText={secondaryButtonText}
        text={text}
        onCancel={onCloseDeleteDeviceModal}
        onOk={onDeleteDevice}
        title={title}
        open={deleteDeviceModalVisible}
      />
      {gatewayId && (
        <AddMaintenanceActionDrawer
          visible={isMaintenanceActionsDrawerVisible}
          gatewayId={gatewayId}
          onClose={onCloseMaintenanceActionsDrawer}
          deviceData={maintenanceDevice}
        />
      )}
    </>
  );
};

export default TabDevices;
