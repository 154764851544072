import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import PeripheralDevices from 'components/organisms/PeripheralDevices';
import { useTabDevices } from './resources/useTabDevices';

const TabDevices: FC = () => {
  const {
    devices,
    peripheralsTitle,
    columns,
    expandedColumns,
    loading,
  } = useTabDevices();

  return (
    <Paper>
      <PeripheralDevices
        loading={loading}
        devices={devices}
        title={peripheralsTitle}
        columns={columns}
        expandedColumns={expandedColumns}
      />
    </Paper>
  );
};

export default TabDevices;
