import { ProjectModel } from 'core/domain/project/model/projectModel';
import { ListResponseBasic } from './general.model';
import { Asset } from './assets.model';

export type ProjectResponse = {
  data: ProjectModel;
  included: {
    assets: Asset[];
  };
};

export type ProjectsResponse = ListResponseBasic<ProjectModel>;

export enum ProjectTab {
  ASSETS = 'assets',
  CAMERAS = 'cameras',
  DOCUMENTS = 'documents',
  GATEWAYS = 'gateways',
  SERVICES = 'services',
  CUSTOMIZATION = 'customization',
  INTERCOMS = 'intercoms',
  LAYOUT = 'layout',
  DEVICES = 'devices',
}
