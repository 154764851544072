import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { GatewayUpdateLatestVersionModel, GatewayUpdateLatestVersionPayload } from '../model';
import { payloadToLastGatewayVersion } from '../mappers';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string, gatewayId: string) => {
  return `${host}/gateway-updates/versions/${gatewayId}/latest`;
};

export const GetGatewayLastVersionByGatewayId = async ({
  token,
  host,
  gatewayId,
}: {
  token: string;
  host: string;
  gatewayId: string;
}): Promise<GatewayUpdateLatestVersionModel> => {
  try {
    const url = getUrl(host, gatewayId);

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<GatewayUpdateLatestVersionPayload>;
    return payloadToLastGatewayVersion(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
