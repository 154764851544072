import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { hasPermission } from 'services/permissions';
import TabsAtom, { TabsAtomProps } from 'components/atoms/TabsAtom';
import { ProjectTab } from 'models/project.model';
import { UserPermissionType } from 'models/users.model';
import { hasIntercomsReadingPermission } from '../TabIntercoms/resources/config';

interface ProjectPageTabsTitlesProps extends TabsAtomProps {
  isLayoutConfigEnabled?: boolean;
}

const TabsTitles: FC<ProjectPageTabsTitlesProps> = ({ isLayoutConfigEnabled, ...props }) => {
  const { t } = useTranslation();

  return (
    <TabsAtom defaultActiveKey={ProjectTab.ASSETS} {...props}>
      <TabsAtom.TabPane tab={t(ProjectTab.ASSETS)} key={ProjectTab.ASSETS} />
      {hasPermission(UserPermissionType.CAMERA_SNAPSHOTS) && (
        <TabsAtom.TabPane tab={t(ProjectTab.CAMERAS)} key={ProjectTab.CAMERAS} />
      )}
      <TabsAtom.TabPane tab={t(ProjectTab.DOCUMENTS)} key={ProjectTab.DOCUMENTS} />
      <TabsAtom.TabPane tab={t(ProjectTab.DEVICES)} key={ProjectTab.DEVICES} />
      <TabsAtom.TabPane tab={t(ProjectTab.GATEWAYS)} key={ProjectTab.GATEWAYS} />
      <TabsAtom.TabPane tab={t(ProjectTab.SERVICES)} key={ProjectTab.SERVICES} />
      <TabsAtom.TabPane tab={t(ProjectTab.CUSTOMIZATION)} key={ProjectTab.CUSTOMIZATION} />
      {hasIntercomsReadingPermission && (
        <TabsAtom.TabPane tab={t(ProjectTab.INTERCOMS)} key={ProjectTab.INTERCOMS} />
      )}
      <TabsAtom.TabPane tab={t(ProjectTab.LAYOUT)} key={ProjectTab.LAYOUT} disabled={!isLayoutConfigEnabled} />
    </TabsAtom>
  );
};

export default TabsTitles;
