import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputRef, TableColumnsType } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { GetDevicesLists } from 'core/domain/devices/repositories/getDevicesList';
import { DeviceModel, DeviceSensorModel } from 'core/domain/devices/models';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import {
  ExpandedPeripheralDevicesColumns,
  ExpandedPeripheralDevicesTranslations,
} from 'components/organisms/PeripheralDevices/ExpandedColumnsPeripheralsDevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from 'components/organisms/PeripheralDevices/SearchPeripheralColumns';
import { ColumnsPeripheralDevices } from '../ColumnsPeripheralDevices';
import { getSharedGatewayPathByGatewayId } from 'components/pages/App/routes/projects/config';

export const useTabDevices = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const history = useHistory();
  const { setMessageError } = useMessage();
  const { projectId } = useParams<{ projectId: string }>();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [searchedColumn, setSearchedColumn] = useState<string>('');

  const searchInput = useRef<InputRef>(null);

  const peripheralsTitle: string = t('_PROJECT_PERIPHERAL_SHARED_DEVICES_TITLE');

  const peripheralExpandedColumnsTranslations: ExpandedPeripheralDevicesTranslations = {
    nameText: t('name'),
    roomText: t('room'),
    typeText: t('type'),
    usageText: t('usage'),
    notReportedText: t('not_reported'),
  };

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
    notReportedText: t('not_reported'),
  };

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const onSelectSharedGateway = (gatewayUuid: string) => {
    history.push(getSharedGatewayPathByGatewayId({ projectId, gatewayId: gatewayUuid }));
  };

  const columns = ColumnsPeripheralDevices({
    columnSearchTranslations,
    searchInput,
    onSearch,
    onReset,
    onSelectSharedGateway,
  });

  const expandedColumns: TableColumnsType<DeviceSensorModel> = ExpandedPeripheralDevicesColumns(peripheralExpandedColumnsTranslations);

  const transformDevices = (data: DeviceModel[]): DeviceModel[] => {
    return data.map((device) => {
      const gatewayId = !!device.gatewayUuid ? `:${device.gatewayUuid}` : '';
      const deviceId = `${device.deviceId}${gatewayId}`;
      return { ...device, deviceId };
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const devicesData = await GetDevicesLists({ host, token, projectId });
      const sharedDevices = devicesData.filter((device) => device.assetId === null);
      const transformedDevices = transformDevices(sharedDevices);
      setDevices(transformedDevices);
    } catch (error) {
      setMessageError({ description: t('_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return {
    devices,
    peripheralsTitle,
    searchedColumn,
    columns,
    expandedColumns,
    loading,
  };
};
