import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import PeripheralDevices from 'components/organisms/PeripheralDevices';
import { useTabDevices } from './resources/useTabDevices';
import ShareSensorModal from 'components/organisms/ShareSensorModal';

const TabDevices: FC = () => {
  const {
    devices,
    peripheralsTitle,
    shareSensorOptions,
    columns,
    expandedColumns,
    loading,
  } = useTabDevices();

  return (
    <>
      <Paper>
        <PeripheralDevices
          loading={loading}
          devices={devices}
          title={peripheralsTitle}
          columns={columns}
          expandedColumns={expandedColumns}
        />
      </Paper>
      <ShareSensorModal
        modalOptions={shareSensorOptions}
        open={shareSensorOptions.shareSensorModalVisible}
        onCancel={shareSensorOptions.onCloseShareSensorModal}
      />
    </>
  );
}

export default TabDevices;