import { FC } from 'react';
import { useAccessesRoomList } from './resources/useAccessesRoomList';
import AccessRoomListTemplate from 'components/template/AccessRoomListTemplate';
import CheckInScannerQRModal from 'components/organisms/CheckInScannerQRModal';
import CheckInCreateRoomDrawer from 'components/organisms/CheckInCreateRoomDrawer';
import CheckInRoomForceSaveModal from 'components/organisms/CheckInRoomForceSaveModal';

const AccessesRoomListTab: FC = () => {
  const {
    checkInCheckOutColumnsList,
    assets,
    currentPage,
    pageSize,
    totalPages,
    checkInPropsStates,
    onChangePage,
    onSearch,
    isLoadingRoomsData,
  } = useAccessesRoomList();

  return (
    <>
      <AccessRoomListTemplate
        list={assets}
        currentPage={currentPage}
        columns={checkInCheckOutColumnsList}
        pageSize={pageSize}
        totalPages={totalPages}
        onSearch={onSearch}
        onChangePage={onChangePage}
        isLoading={isLoadingRoomsData}
      />
      <CheckInCreateRoomDrawer {...checkInPropsStates} />
      <CheckInRoomForceSaveModal {...checkInPropsStates.modalForce} />
      <CheckInScannerQRModal {...checkInPropsStates.qrModal} />
    </>
  )
}

export default AccessesRoomListTab;